import React, {
    useState,
    useEffect
} from "react";
import {
    Link,
    useNavigate
} from "react-router-dom";
import Select from 'react-select';

 import {SiteLogo,AkuserId,
    frontUrl,
    Url,
    Redirectdashboard_Terms,
    validEmailRegex,
    validPhoneRegex,
    validPasswordRegex,
    CommonNotify,
    checkEmptyUndefined,
    Notifyalert,
    SetNotificationAfterName,
    RemoveNotificationAfterName,
    GetSchoolsCommon
} from '../Frontapi.js';
import Header from '../includes/Header';
 import Footer from '../includes/Footer';






const Registerstudent = () => {
 Redirectdashboard_Terms();


        const [email, setEmail] = useState("");

         const [username, setUsername] = useState("");
        
        const [name, setName] = useState("");
        const [lastname, setLastname] = useState("");
        const [mobile, setMobile] = useState("");
        const [school, setSchool] = useState("");
        const [standard, setStandard] = useState("");
        const [university, setUniversity] = useState("");
        const [pincode, setPincode] = useState("");

        const [password, setPassword] = useState("");
        const [confirm_password, setConfirmpassword] = useState("");

        const [latitude, setLatitude] = useState("");
        const [longitude, setLongitude] = useState("");



        const [registersection, showRegistersection] = useState(true);
        const [addschoolsection, showAddschoolsection] = useState(false);
        const [addstandardsection, showAddstandardsection] = useState(false);

        const [schoolname, setSchoolName] = useState("");
        const [schoolpincode, setSchoolPincode] = useState("");

        

        const [standardname, setSubjectName] = useState("");
        const [schoolsList, setschoolsList] = useState([]);
        const [standardsList, setstandardsList] = useState([]);

        /***Schools only***/
        const [Schooloptions, setschoolsOptions] = useState();
        const [selectedSchoolOption, setselectedSchoolOption] = useState({ value: '', label: 'Select School*' } )

       const  emptyselectedSchoolOption =() => {
          setselectedSchoolOption({ value: ' ', label: ' ' })
        }
        const handleChange = (value) => {
        document.getElementsByClassName('select-school-input')[0].classList.remove('notify-input-missing');
        setSchool(value.value);
        setselectedSchoolOption(value)
        }
   /***Schools only***/




           /***Standard only***/

 


        const [Standardoptions, setstandardOptions] = useState([

 
{ value: '5', label:   '5th'  },
{ value: '6', label:   '6th'  },
{ value: '7', label:   '7th'  },
{ value: '8', label:   '8th'  },
{ value: '9', label:   '9th'  },
{ value: '10', label:   '10th'  },
{ value: '11', label:   '11th'  },
{ value: '12', label:   '12th'  }]);
        const [selectedStandardOption, setselectedStandardOption] = useState({ value: '', label: 'Select Standard*' }  )

           const  emptyselectedStandardOption =() => {
          setselectedStandardOption({ value: ' ', label: ' ' })
        }
        const handleChangeStandard = (value) => {
        document.getElementsByClassName('select-standard-input')[0].classList.remove('notify-input-missing');
        setStandard(value.value);
        setselectedStandardOption(value)
        }
           /***Standard only***/



            const showhidepassword = (inputid) =>{
                var inputtype=  document.getElementById(inputid+"input").attributes.getNamedItem('type').value;
                var iconelemenent=  document.getElementById(inputid+"icon");
                if(inputtype == 'text'){
                document.getElementById(inputid+"input").setAttribute('type','password');
                iconelemenent.classList.remove('fa-eye');
                iconelemenent.classList.add('fa-eye-slash');
                }else{
                document.getElementById(inputid+"input").setAttribute('type','text');
                iconelemenent.classList.add('fa-eye');
                iconelemenent.classList.remove('fa-eye-slash');
                }
            }

 const MobileNumberValidation=(text)=>{
   var returnval
   if(text.split('').length > 10 || ( /^\d+$/.test(text))  == false){

      returnval = false
  }  else{
    returnval = true;
  }
 
   return returnval;
 
}

const PincodeValidation=(text)=>{
     var returnval
   if(text.split('').length > 7 || ( /^\d+$/.test(text))  == false){

      returnval = false
  }  else{
    returnval = true;
  }

  
   return returnval;
 
}


        const handleUserInput = (e) => {
            RemoveNotificationAfterName();

            if(e.keyCode === 13){
            signUp(e);
            }
            var name = e.target.name;
            var value = e.target.value;
             switch (name) {

                 case 'username':
                      e.target.value = value.toLowerCase().trim()
                    setUsername(value.toLowerCase().trim());
                     
                     
                    break;

                case 'name':
                    setName(value.trim());
                     
                    break;
                case 'lastname':
                    setLastname(value.trim());
                    
                    break;
                case 'school':
                    setSchool(value);
                    
                    break;
                case 'standard':
                    //setSubject(value);
                    
                    break;
                case 'university':
                    setUniversity(value);
                    
                    break;
                 case 'pincode':
                      ( PincodeValidation(value) == true) ? setPincode(value) : setPincode('');
                      
                   
                    break;         
                case 'mobile':
                     ( MobileNumberValidation(value) == true) ? setMobile(value) : setMobile('');
                    
                    break;            
                case 'email':
                    setEmail(value);
                    checkEmailExists(value);
                    break;
                case 'password':
                    setPassword(value)
                    break;
                case 'confirm_password':
                    setConfirmpassword(value)
                    break;     
            }
            //  (email != '' && password != '') ? setformValid(true): setformValid(false)
        }
        const checkEmailExists = async (e) => {}
         const showPosition = async (position)  =>{

            setLatitude(position.coords.latitude)
             setLongitude(position.coords.longitude)
 
    }
        const signUp = async (e) => {
            e.preventDefault();

       // navigator.geolocation.getCurrentPosition(showPosition);


            /***Check Required***/
            var required_fields= document.getElementsByClassName('MustEnterField')
             if(document.getElementsByClassName('notify-input-text')[0]){
                document.getElementsByClassName('notify-input-text')[0].remove('notify-input-missing');
             } 
            var error=0;
            Array.prototype.forEach.call(required_fields, function(el) {
                        el.classList.remove('notify-input-missing');
                        el.classList.add('mb-25');
                        if(el.value === ''  && error == 0  ){
                        var inputname =  el.name.charAt(0).toUpperCase() + el.name.slice(1);
                        el.classList.add('notify-input-missing');
                        el.classList.remove('mb-25');
                        error=1;

                       /* var element= document.getElementsByName(el.name)[0] ;
                        var requiredelement = document.createElement("div");
                        requiredelement.classList.add('notify-input-text');
                        requiredelement.innerHTML= inputname + ' required'
                        el.after(requiredelement);
                        element.focus();*/

                        SetNotificationAfterName(el.name, inputname + ' required','noneed')
             }
             });
             /***Check Required***/


            if(error === 1){
                    return false
            }
             if(school == ''){
                
                  document.getElementsByClassName('select-school-input')[0].classList.add('notify-input-missing');
                  return false
            }
            if(standard == ''){
                
                  document.getElementsByClassName('select-standard-input')[0].classList.add('notify-input-missing');
                  return false
            }

            if(validEmailRegex.test(email) == false){
                

                 SetNotificationAfterName('email','Invalid Email')
                return false
            }

            //  var pattern = /^\d+\.?\d*$/;


            // if(pattern.test(mobile) == false){
                
            //     SetNotificationAfterName('mobile','Invalid Phone')
            //    return false
            // }
            if(validPasswordRegex.test(password) == false){
                

                // SetNotificationAfterName('password','Password is too weak')
              //  return false
            }


            

             if( password  !==  confirm_password  ){

                SetNotificationAfterName('confirm_password','Password and Confirm Password Dont Match')
                return false
  
            }
 

           


             

            var postdata = {
                email: email,
                username:username,
                name:name,
                lastname:lastname,
                mobile:mobile,
                school:school,
                standard:standard,
                university:university,

                password: password,
                  pincode: pincode,
                    latitude:latitude,
                longitude:longitude,
                usertype:2,
                referred:    localStorage.getItem('AKreferralcode')
                
            };
            try {
                const response = await fetch(Url + "userregister", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            postdata
                        }),
                    }).then((response) => response.json())
                    .then((data) => {
                        if (data.success) {
                            Notifyalert('success', data.message)
                            document.getElementById("RegistrationForm").reset();

                             var userdata=data.data;
                             localStorage.setItem('AKuserId', userdata._id)
                             localStorage.setItem('AKusername', userdata.username)
                            localStorage.setItem('AKusertype', userdata.usertype)
                            setTimeout(function(){

                            //Notifyalert('success',data.message)
                            Redirectdashboard_Terms(userdata._id,userdata.usertype)

                            },1000)


                        } else {
                            Notifyalert('error', data.message)
                        }
                    })
            } catch (error) {}
        };
        const handleSchoolInput = (e) => {
            RemoveNotificationAfterName();
            var name = e.target.name;
            var value = e.target.value;
            switch (name) {
                case 'schoolname':
                    setSchoolName(value);
                    break;
                case 'schoolpincode':
                    setSchoolPincode(value)
                    break;
            }
            //  (email != '' && password != '') ? setformValid(true): setformValid(false)
        }
        const Addschool = async (e) => {
            e.preventDefault();


            /***Check Required***/
            var required_fields= document.getElementsByClassName('MustSchoolEnterField')
             if(document.getElementsByClassName('notify-input-text')[0]){
                document.getElementsByClassName('notify-input-text')[0].remove('notify-input-missing');
             } 
            var error=0;
            Array.prototype.forEach.call(required_fields, function(el) {
                        el.classList.remove('notify-input-missing');
                        el.classList.add('mb-25');
                        if(el.value === '' && error == 0){
                        var inputname =  el.name.charAt(0).toUpperCase() + el.name.slice(1);
                        el.classList.add('notify-input-missing');
                        el.classList.remove('mb-25');
                        error=1;

                       /* var element= document.getElementsByName(el.name)[0] ;
                        var requiredelement = document.createElement("div");
                        requiredelement.classList.add('notify-input-text');
                        requiredelement.innerHTML= inputname + ' required'
                        el.after(requiredelement);
                        element.focus();*/

                        SetNotificationAfterName(el.name, inputname + ' required')
             }
             });
             /***Check Required***/

            if(error === 1){
                    return false
            }



            
            try {
                var postdata = {
                    name: schoolname,
                    pincode: schoolpincode,
                    status: 1
                };
                const response = await fetch(Url + "updateschool", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            postdata
                        }),
                    }).then((response) => response.json())
                    .then((data) => {
                        if (data.success) {
                            Notifyalert('success', data.message)
                            var schooldata=data.data;
                            GetSchools()
                             setselectedSchoolOption({ value: schooldata._id, label:schooldata.name })
                             setSchool(schooldata._id)
                            showRegister()
                        } else {
                            Notifyalert('error', data.message)
                        }
                    })
            } catch (error) {}
        }
        const handleSubjectInput = (e) => {
            RemoveNotificationAfterName();
            var name = e.target.name;
            var value = e.target.value;
            switch (name) {
                case 'standardname':
                    setSubjectName(value);
                    break;
            }
            //  (email != '' && password != '') ? setformValid(true): setformValid(false)
        }
        const Addstandard = async (e) => {
            e.preventDefault();

           


            if (checkEmptyUndefined(standardname) === false) {
                Notifyalert('error', '* Fields Required')
                return false
            }
            try {
                var postdata = {
                    name: standardname,
                    status: 1
                };
                const response = await fetch(Url + "updatestandard", {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                        },
                        body: JSON.stringify({
                            postdata
                        }),
                    }).then((response) => response.json())
                    .then((data) => {
                        if (data.success) {
                            Notifyalert('success', data.message)
                            GetSubjects()
                        } else {
                            Notifyalert('error', data.message)
                        }
                    })
            } catch (error) {}
        }
        const showAddSchool = async (e) => {
            RemoveNotificationAfterName();
            showAddschoolsection(true);
            showRegistersection(false);
            showAddstandardsection(false);
        }
        const showAddSubject = async (e) => {
              RemoveNotificationAfterName();
            showAddstandardsection(true);
            showRegistersection(false);
            showAddschoolsection(false);
        }
        const showRegister = async (e) => {
            showRegistersection(true);
            showAddschoolsection(false);
            showAddstandardsection(false);
        }

        const GetSchools = async (value, reset = '') => {
            (async function() { 

             setschoolsOptions  ( await GetSchoolsCommon())

             })()
        };

        const GetSubjects = async (value, reset = '') => {
            var postdata = {active:1};
            const response = await fetch(Url + "getstandards", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        postdata
                    }),
                }).then((response) => response.json())
                .then((data) => {
                    if (data.success) {
                        setstandardsList(data.data);
                    } else {
                        ;
                    }
                })
        };

  const schooloptionselectbox = (event) => {
          setSchool('')
           emptyselectedSchoolOption()
        }


        useEffect(() => {
            GetSchools();
             GetSubjects();
                         document.getElementsByClassName("select-register-input")[0].addEventListener("click", schooloptionselectbox);

        }, []);


  return (


    <div>

      <Header pageInfo="Student Register" />


    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 page-wrapper-section"> 



    
    <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12 col-xs-9 full-section"> 

        {(AkuserId ==  null || AkuserId == '' || typeof AkuserId =='undefined'  ) ? 

      <div className="page-content">

      <div className="register-container"> 

        {(addschoolsection === true)?  
         <div className="register-section"  > 

          <h1 className="register-title cur-poi" onClick={showRegister}> <span className="goto-section" >   &lt; </span> Add my School Name </h1>

           <input   type="text" name="schoolname" className="form-control register-input mb-25 MustSchoolEnterField"   placeholder="School Name*"  
                                onKeyUp={(e) => handleSchoolInput(e)}
                             required/>
           <input   type="text" name="schoolpincode" className="form-control register-input mb-25 MustSchoolEnterField"   placeholder="Pincode"  
                                onKeyUp={(e) => handleSchoolInput(e)}
                             required/>                   
           <button className="register-button"  onClick={Addschool}>Add my School</button>     
                    
         </div>: ''}

         {(addstandardsection === true)?  
         <div className="register-section"> 

          <h1 className="register-title cur-poi" onClick={showRegister}> <span className="goto-section" >   &lt; </span> Add my Subject Name </h1>

           <input   type="text" name="standardname" className="form-control register-input mb-25"   placeholder="Subject Name*"  
                                onKeyUp={(e) => handleSubjectInput(e)}
                             required/>
                             
           <button className="register-button "  onClick={Addstandard}>Add my Subject</button>     
                    
         </div>: ''}


          
          <div className="register-section" style={(registersection === true)? {display:"block"} : {display:"none"}  }  > 
          <form autoComplete="off" id="RegistrationForm"  >
          <h1 className="register-title">Register as Student</h1>

            <input   type="text" name="username" className="form-control register-input mb-25 MustEnterField"   placeholder="Username*"  
                                onKeyUp={(e) => handleUserInput(e)}
                             required/>

              <input   type="text" name="name" className="form-control register-input mb-25 MustEnterField"   placeholder="Name*"  
                                onKeyUp={(e) => handleUserInput(e)}
                             required/>

                <input   type="text" name="lastname" className="form-control register-input mb-25 MustEnterField"   placeholder="Last Name*"  
                                onKeyUp={(e) => handleUserInput(e)}
                             required/>

               <input   type="text" name="email" className="form-control register-input mb-25 MustEnterField"   placeholder="Email*"  
                                onKeyUp={(e) => handleUserInput(e)}
                             required/>

                <input   type="text" name="mobile" value={mobile} className="form-control register-input mb-25 MustEnterField"   placeholder="Mobile*"  
                                onChange={(e) => handleUserInput(e)}
                             required/>

                
                <Select className="form-control   MustEnterField select-register-input select-school-input" 
                name={"school"}
                value={selectedSchoolOption}
                onChange={handleChange}
                options={Schooloptions}
                  /> 


                <p className="register-add-inst" onClick={showAddSchool}  > Can't find my School Name </p>    




                <Select className="form-control   MustEnterField select-register-input select-standard-input" 
                name={"standard"}
                value={selectedStandardOption}
                onChange={handleChangeStandard}
                options={Standardoptions}
                 />  



                 {/*
                <div className="select-standard-input">
                <select name="standard" className="form-control register-input mb-25 MustEnterField" onChange={(e) => handleUserInput(e)} >
                <option value="">Standard*</option>
                <option value="1" >1st</option>
                <option value="2" >2nd</option>
                <option value="3" >3rd</option>
                <option value="4" >4th</option>
                <option value="5" >5th</option>
                <option value="6" >6th</option>
                <option value="7" >7th</option>
                <option value="8" >8th</option>
                <option value="9" >9th</option>
                <option value="10" >10th</option>
                <option value="11" >11th</option>
                <option value="12" >12th</option>

                
                </select>         
                </div>   */ }    
 

                 <input  className="form-control register-input mb-25 MustEnterField"   type="text" name="university" placeholder="University*"  
                                onKeyUp={(e) => handleUserInput(e)}
                             required/>

 <input  className="form-control register-input mb-25 MustEnterField" value={pincode}   type="text" name="pincode" placeholder="Pincode*"  onChange={(e) => handleUserInput(e)}
                             required/>             

              <div className="password-input-box">               
              <input id="togglepasswordinput" className="form-control register-input mb-25 MustEnterField"   type="password" name="password" placeholder="Password*"  
                                onKeyUp={(e) => handleUserInput(e)}
                             required/>
                             <i id="togglepasswordicon" onClick={(inputname) =>showhidepassword('togglepassword')} className=" fa fa-eye-slash"></i>
             </div>

             <div className="password-input-box">   
               <input id="togglecpasswordinput"  className="form-control register-input mb-25 MustEnterField"   type="password" name="confirm_password" placeholder="Confirm Password*"  
                                onKeyUp={(e) => handleUserInput(e)}
                             required/>
                              <i id="togglecpasswordicon" onClick={(inputname) =>showhidepassword('togglecpassword')}  className=" fa fa-eye-slash"></i>
             </div>
                                            
                  
              <button className="register-button"  onClick={signUp}>Submit</button>     
              

              <p className="register-signup-inst"  >Already have an account? <Link to={frontUrl+'login'} >Login</Link> </p>     

              </form>
                                             
          </div> 

      </div>
      </div>


 : ''}

 <Footer />

    </div>


    </div>



    </div>


 
 
  );
};
export default Registerstudent;